export default class Parcel {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.countsItems = data ? data.countsItems : '';
    this.description = data ? data.description : '';
    this.type = data ? data.type : '';
    this.reference = data ? data.reference : '';
    this.weight = data ? data.weight : 0;
    this.volume = data ? data.volume : 0;
    this.height = data ? data.height : 0;
    this.width = data ? data.width : 0;
    this.length = data ? data.length : 0;
  }
}
