import GeisDefaultValues from './GeisDefaultValues';

export default class Geis {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.clientId = data ? data.clientId : '';
    this.configurationName = data ? data.configurationName : '';
    this.password = data ? data.password : '';
    this.metadata = data ? data.metadata : [];
    this.courier = data ? data.courier : '';
    this.customerCode = data ? data.customerCode : '';
    this.language = data ? data.language : '';
    this.urlAddress = data ? data.urlAddress : '';
    this.distributionChannel = data ? data.distributionChannel : '';
    this.format = data ? data.format : '';
    this.position = data ? data.position : '';
    this.geisDefaultValues = new GeisDefaultValues();
  }
}
