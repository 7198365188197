export default class Contact {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.email = data ? data.email : '';
    this.fullName = data ? data.fullName : '';
    this.phone = data ? data.phone : '';
  }
}
