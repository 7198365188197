export default class COD {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.amount = data ? data.amount : '';
    this.currency = data ? data.currency : '';
    this.externalClientSymbol = data ? data.externalClientSymbol : '';
    this.iban = data ? data.iban : '';
  }
}
