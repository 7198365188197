import Address from './Address';
import Contact from './Contact';
import Parcel from './Parcel';
import Insurance from './Insurance';
import COD from './COD';
import ReturnOfDocument from './ReturnOfDocument';

export default class GeisDefaultValues {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.burstId = data ? data.burstId : '';
    this.note = data ? data.note : '';
    this.noteDriver = data ? data.noteDriver : '';
    this.partnerNumber = data ? data.partnerNumber : '';
    this.reference = data ? data.reference : '';
    this.shipmentNumber = data ? data.shipmentNumber : '';
    this.coverAddress = new Address();
    this.deliveryAddress = new Address();
    this.deliveryContact = new Contact();
    this.exportItem = new Parcel();
    this.insurance = new Insurance();
    this.cashOnDelivery = new COD();
    this.returnOfDocument = new ReturnOfDocument();
  }
}
