<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.parcel').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="description"
              :title="$t('courierSet.parcelDescription')"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="type"
              :title="$t('courierSet.parcelType')"
              rules="required"
              @input="update"
            />
          </v-col>
          <v-col>
            <text-field
              v-model="reference"
              :title="$t('courierSet.referenceNumber')"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      elevation="0"
      class="mt-4"
    >
      <v-card-title class="pb-0">
        {{ $t('courierSet.additionalData').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="partnerNumber"
              :title="$t('courierSet.partnerNumber')"
              @input="update"
            />
          </v-col>

          <v-col>
            <text-field
              v-model="parcelReference"
              :title="$t('courierSet.parcelReference')"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="shipmentNumber"
              title="Numer wysyłki"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';

export default {
  components: {
    TextField,
  },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    confSet: null,
    exportItem: null,
    id: null,
    tenantId: null,
    valid: null,
    countsItems: null,
    description: null,
    type: null,
    reference: null,
    weight: null,
    volume: null,
    height: null,
    width: null,
    length: null,
    partnerNumber: null,
    parcelReference: null,
    shipmentNumber: null,
  }),

  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.exportItem = {
        parameters: {
          id: this.id,
          tenantId: this.tenantId,
          clientEntityState: this.isNew ? 4 : 3,
          countsItems: this.countsItems,
          description: this.description,
          type: this.type,
          reference: this.reference,
          weight: this.weight,
          volume: this.volume,
          height: this.height,
          width: this.width,
          length: this.length,
        },
        partnerNumber: this.partnerNumber,
        reference: this.parcelReference,
        shipmentNumber: this.shipmentNumber,
        clientEntityState: this.isNew ? 4 : 3,
      };
      this.$emit('input', this.exportItem);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.exportItem = this.confSet?.geisDefaultValues?.exportItem;
      this.id = this.exportItem?.id ? this.exportItem?.id : null;
      this.tenantId = this.exportItem?.tenantId ? this.exportItem?.tenantId : null;
      this.countsItems = this.exportItem?.countsItems ? this.exportItem?.countsItems : null;
      this.description = this.exportItem?.description ? this.exportItem?.description : null;
      this.type = this.exportItem?.type ? this.exportItem?.type : null;
      this.reference = this.exportItem?.reference ? this.exportItem?.reference : null;
      this.weight = this.exportItem?.weight ? this.exportItem?.weight : null;
      this.volume = this.exportItem?.volume ? this.exportItem?.volume : null;
      this.height = this.exportItem?.height ? this.exportItem?.height : null;
      this.width = this.exportItem?.width ? this.exportItem?.width : null;
      this.length = this.exportItem?.length ? this.exportItem?.length : null;
      this.partnerNumber = this.confSet?.geisDefaultValues?.partnerNumber
        ? this.confSet?.geisDefaultValues?.partnerNumber
        : null;
      this.parcelReference = this.confSet?.geisDefaultValues?.reference
        ? this.confSet?.geisDefaultValues?.reference
        : null;
      this.shipmentNumber = this.confSet?.geisDefaultValues?.shipmentNumber
        ? this.confSet?.geisDefaultValues?.shipmentNumber
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select {
  padding: 0px 10px 0px 10px;
}
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
