export default class Address {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.name = data ? data.name : '';
    this.name2 = data ? data.name2 : '';
    this.street = data ? data.street : '';
    this.city = data ? data.city : '';
    this.zipCode = data ? data.zipCode : '';
    this.country = data ? data.country : '';
  }
}
