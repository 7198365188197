<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.serviceInfo').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="language"
              :title="$t('courierSet.language')"
              rules="required"
              :filed-items="languageItems"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="distributionChannel"
              :title="$t('courierSet.distributionChannel')"
              rules="required"
              :filed-items="distributionChannelItems"
              @input="update"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <select-field
              v-model="format"
              :title="$t('courierSet.labelFormat')"
              rules="required"
              :filed-items="formatItems"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <select-field
              v-model="position"
              :title="$t('courierSet.position')"
              rules="required"
              :filed-items="positionItems"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      elevation="0"
      class="mt-3"
    >
      <v-card-title class="pb-0">
        {{ $t('courierSet.additionalData').toUpperCase() }}
      </v-card-title>

      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="addressCode"
              :title="$t('courierSet.addressCode')"
              @input="update"
            />
            <text-field
              v-model="burstId"
              :title="$t('courierSet.burstId')"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="note"
              :title="$t('courierSet.note')"
              @input="update"
            />
          </v-col>
          <v-col>
            <text-field
              v-model="noteDriver"
              :title="$t('courierSet.noteDriver')"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';

export default {
  components: { TextField, SelectField },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },

  data: () => ({
    confSet: null,
    valid: null,
    tentantId: null,
    language: null,
    distributionChannel: null,
    format: null,
    position: null,
    burstId: null,
    note: null,
    noteDriver: null,
    addressCode: null,
    returnOfDocumentId: null,
    returnOfDocument: null,
    hintPosition: `Określa pozycję etykiety w pliku PDF (na jednej stronie formatu A4 mieszczą się 4 etykiety). 
      Dozwolone wartości: 1, 2, 3, 4. Dla Formatu np. 5 – PDF proszę używać zawsze wartości 1.`,

    hintformat: `Określa format generowanego pliku. Dozwolone wartości: 1 – PDF, 2 – EPL, 3 - ZPL,
       4 – BMP (plik BMP jest tworzony z pliku TIFF), 5 – PDF (rozmiar etykiety 10x15 dla drukarek termicznych)`,
    languageItems: [
      {
        id: 1,
        name: 'CZ',
      },
      {
        id: 2,
        name: 'SK',
      },
      {
        id: 3,
        name: 'EN',
      },
      {
        id: 4,
        name: 'DE',
      },
      {
        id: 5,
        name: 'PL',
      },
    ],

    distributionChannelItems: [
      {
        id: '1',
        name: 'Parcel',
      },
      {
        id: '2',
        name: 'Cargo',
      },
    ],

    formatItems: [
      {
        id: 1,
        name: 'PDF',
      },
      {
        id: 2,
        name: 'EPL',
      },
      {
        id: 3,
        name: 'ZPL',
      },
      {
        id: 4,
        name: 'BMP (plik BMP jest tworzony z pliku TIFF)',
      },
      {
        id: 5,
        name: 'PDF (rozmiar etykiety 10x15 dla drukarek termicznych)',
      },
    ],
    positionItems: [
      {
        id: 1,
        name: 'Lewy górny róg',
      },
      {
        id: 2,
        name: 'Prawy górny róg',
      },
      {
        id: 3,
        name: 'Lewy dolny róg',
      },
      {
        id: 4,
        name: 'Prawy dolny róg',
      },
    ],
    requiredRule: [(v) => !!v || 'To pole jest wymagane!'],
  }),
  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.serviceParameters = {
        parameters: {
          clientEntityState: this.isNew ? 4 : 3,
          tentantId: this.tentantId,
          language: this.language,
          distributionChannel: this.distributionChannel,
          format: this.formatItems.find((x) => x.name === this.format)?.id,
          position: this.positionItems.find((x) => x.name === this.position)?.id,
          burstId: this.burstId,
          note: this.note,
          noteDriver: this.noteDriver,
          returnOfDocument: {
            clientEntityState: this.isNew ? 4 : 3,
            Id: this.returnOfDocumentId,
            tentantId: this.tentantId,
            addressCode: this.addressCode,
          },
        },
      };
      this.$emit('input', this.serviceParameters);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.tentantId = this.confSet ? this.confSet.tentantId : null;
      this.language = this.confSet ? this.confSet.language : null;
      this.distributionChannel = this.confSet ? this.confSet.distributionChannel : null;
      this.format = this.confSet ? this.confSet.format : null;
      this.position = this.confSet ? this.confSet.position : null;

      this.additionalData = this.confSet?.geisDefaultValues;
      this.burstId = this.additionalData?.burstId ? this.additionalData?.burstId : null;
      this.note = this.additionalData?.note ? this.additionalData?.note : null;
      this.noteDriver = this.additionalData?.noteDriver ? this.additionalData?.noteDriver : null;

      this.returnOfDocument = this.confSet?.geisDefaultValues?.returnOfDocument;
      this.returnOfDocumentId = this.returnOfDocument?.id ? this.returnOfDocument?.id : null;
      this.addressCode = this.returnOfDocument?.addressCode
        ? this.returnOfDocument?.addressCode
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select {
  padding: 0px 10px 0px 10px;
}
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
